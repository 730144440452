import { PLATFORM_ID, inject } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as themeActions from '../actions/theme.action';

import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export const setTheme$ = createEffect(
  (actions$ = inject(Actions), platform = inject(PLATFORM_ID)) => {
    return actions$.pipe(
      ofType(themeActions.SetTheme),
      tap(({ theme }) => {
        if (isPlatformBrowser(platform)) {
          document.body.setAttribute('data-bs-theme', theme);
        }
      })
    );
  },
  { functional: true, dispatch: false }
);
